<template>
    
    <Section :options="sectionOptions">

            <form id="login-form" name="login-form" class="login-form mb-0" action="#" method="post" @submit="onLoginFormSubmit">

                <div class="row">
                    <div class="col-12">
                        <h3 class="title">{{ labels.title }}</h3>
                        <span class="register-link">
                            {{ labels.no_account }}
                            <router-link :to="getRegisterPath()">{{ labels.register }}</router-link></span>
                    </div>
                </div>
                

                <validation-observer v-slot="{ invalid }" slim>
                    <div class="row">
                        <TextField 
                            v-bind:value.sync="form.login" 
                            :label="labels.login" 
                            :placeholder="labels.login_placeholder" 
                            :rowClass="{'col-lg-12':true, 'form-group':true}" 
                            :inputClass="{'form-control':true, 'sm-form-control':true}" 
                            required 
                        />
                        <TextField 
                            v-bind:value.sync="form.password" 
                            :label="labels.password" 
                            :placeholder="labels.password_placeholder" 
                            :rowClass="{'col-lg-12':true, 'form-group':true}" 
                            :inputClass="{'form-control':true, 'sm-form-control':true}" 
                            fieldType="password"
                            required 
                        />

                        <div class="col-12 form-group">
                            <button :disabled="invalid" class="button button-3d button-black m-0 ladda-button" data-style="zoom-in" id="login-form-submit" name="login-form-submit" value="login" type="submit">{{labels.button}}</button>
                            <a href="javascript:void(0)" @click="onResetPasswordClick" class="float-end">{{labels.forgot_password}}</a>
                        </div>
                    </div>
                </validation-observer>

            </form>

    </Section>

</template>

<style>

h3.title {
    float:left;
}

span.register-link {
    float:right;
}

@media (max-width: 575.98px) {
    h3.title {
        float:none;
        margin-bottom: 15px;
    }
    span.register-link {
        float:none;
        margin-bottom: 15px;
        display:block;
    }
  }

.login-form {
    min-height:50vh;
    max-width:800px;
    margin-left:auto;
    margin-right:auto;
}

</style>

<script lang="ts">
import { defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref } from '@fwk-node-modules/vue'
import TextField from '@fwk-client/components/panels/input/TextField.vue';
import Section, { SectionOptions } from '@root/src/client/components/panels/layouts/canvas/Section.vue';
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import * as api from '@fwk-client/utils/api';
import * as Ladda from 'ladda';
import { types as layoutTypes } from '@root/src/client/store/layout';
import { updateApplicationDataForLoggedInUser } from '@root/src/client/utils/application';
import { languagesTypes } from '@fwk-client/store/types';

/** @cmsOptions */
export interface LoginOptions {
    /** @cmsType CmsInterface */
    sectionOptions?:SectionOptions
}

/** @cmsLabels */
export interface LoginLabels {
    
}

/** @cmsSlots */
export interface LoginSlots {
    
}

export default defineComponent({
    props: {
        options: Object as PropType<LoginOptions>,
        labels: {
          type: Object as PropType<LoginLabels>,
          default: () => { return {} }
        },
        components: Object as PropType<LoginSlots>
    },
    components: {
        Section,
        TextField
    },
    setup(props, context) {
        const app = getApp();
        const $router = useRouter();
        const $store = useStore();

        const form = {
            login : "",
            password : "",
            rememberMe : false
        }

        var laddaSubmit:Ladda.LaddaButton|null = null;

        const labels = {
            title: app.$t('authentication.login.title'),
            login: app.$t('authentication.login.form.email'),
            login_placeholder: app.$t('authentication.login.form.email_placeholder'),
            password: app.$t('authentication.login.form.password'),
            password_placeholder: app.$t('authentication.login.form.password_placeholder'),
            button: app.$t('authentication.login.button'),
            forgot_password: app.$t('authentication.login.forgot-password'),
            no_account: app.$t('authentication.login.no-account'),
            register: app.$t('authentication.login.register-link'),
        }
        

        const computedOptions:LoginOptions = {
            sectionOptions : {
                
            },
            ...props.options
        };

        const onLoginFormSubmit = (evt:Event) => {
            evt.preventDefault();

            var options:api.ApiVueOptions =  {
                app: app
            }

            var input = {
                ...form,
                rememberMe : form.rememberMe,
                redirect: app.$route.query.redirect
            }

            laddaSubmit!.start();
            
            api.postAPI('/api/auth/login', input, options).then((response:any) => {
                if(response.isLoggedIn && response.applicationDataForLoggedInUser) {
                    // We update the application data for logged in user
                    var applicationDataForLoggedInUser = response.applicationDataForLoggedInUser;
                     updateApplicationDataForLoggedInUser($store, applicationDataForLoggedInUser);
                    // We redirect to logged in page
                    $router.push(response.redirect).catch((err:any) => {});
                }
                else {
                    laddaSubmit!.stop();
                }
            });
        }

        const onResetPasswordClick = (evt:Event) => {
            $store.commit('layout/'+ layoutTypes.mutations.SHOW_RESET_PASSWORD_MODAL_SWITCH);
        }

        const getRegisterPath = () => {
            // We display register page
            var languageCode = $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];
            return {
                name : 'register',
                params : {
                    lang : languageCode
                }
            }
        }

        onMounted(() => {
            var button:HTMLButtonElement|null = document.querySelector( 'form[name=login-form] button.ladda-button' );
            laddaSubmit = Ladda.create(button!);
        })

        onBeforeUnmount(() => {
        })

        return {
            sectionOptions:computedOptions.sectionOptions,
            form,
            labels,
            getRegisterPath,
            onLoginFormSubmit,
            onResetPasswordClick
        }

    }
})
</script>