import { Store } from '@fwk-node-modules/vuex';
import { authenticationTypes } from '@fwk-client/store/types';

/**
 * updateApplicationDataForLoggedInUser
 * This method is called for logged in user to update application with data needed associated to the logged in user.
 * It should be called after user is logged in or when loading application with logged in user
 * @param store - the store instance.
 * @param applicationData - the computed application data generated by server.hooks.buildApplicationDataForLoggedInUser.
 *    - modules - the list of modules the user is allowed to access
 *    - user - the logged in user information
 *    - isLanguageSwitchAllowed - the possibility to switch language.
 *    - isConfigAllowed - the possibility to configurre the application
 *    - workerContract - the worker contract if available for the user company.
 */
export function updateApplicationDataForLoggedInUser (store:Store<any>, applicationData:any) {
    // We update the authentication
    store.commit('authentication/' + authenticationTypes.mutations.AUTH_SUCCESS, applicationData.user);
}